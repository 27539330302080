import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
//import PreviewCompatibleImage from './PreviewCompatibleImage'

// function copyMyText() {
//      //select the element with the id "copyMe", must be a text box
//      var textToCopy = document.getElementById("copyMe");
//      //select the text in the text box
//      textToCopy.select();
//      //copy the text to the clipboard
//      document.execCommand("copy");
// }

class ItemRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <ol>
        {posts &&
         posts.map(({ node: post }) => (
            <li key={post.frontmatter.smuid}>
              <article className={`item-list-item tile is-child box notification`}>
                <header>
                  <p className="post-meta">
                     <Link className="has-text-primary" to={post.fields.slug}> {post.frontmatter.title} </Link>                 
                     <span> - <a href={"/admin/#/collections/item/entries/" + post.frontmatter.smuid}>{post.frontmatter.smuid}</a> - ({post.frontmatter.date}) </span>
                     <p> {post.frontmatter.internalnotes} </p>
                     <p> {post.rawMarkdownBody} </p>
                     <p> {JSON.stringify(post.frontmatter.answersets)} </p>
                  </p>
                </header>
              </article>
            </li>
          ))}
      </ol>
    )
  }
}

ItemRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query ItemRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "item-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              rawMarkdownBody
              frontmatter {
                title
                smuid
                internalnotes
                answersets {
                  vars {
                    varval
                  }
                }
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ItemRoll data={data} count={count} />}
  />
)

// export default () => (
//   <StaticQuery
//     query={graphql`
//       query ItemRollQuery {
//         allMarkdownRemark(
//           sort: { order: DESC, fields: [frontmatter___date] }
//           filter: { frontmatter: { templateKey: { eq: "item-post" } } }
//         ) {
//           edges {
//             node {
//               excerpt(pruneLength: 400)
//               id
//               fields {
//                 slug
//               }
//               frontmatter {
//                 title
//                 templateKey
//                 date(formatString: "MMMM DD, YYYY")
//                 featuredpost
//                 featuredimage {
//                   childImageSharp {
//                     fluid(maxWidth: 120, quality: 100) {
//                       ...GatsbyImageSharpFluid
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     `}
//     render={(data, count) => <ItemRoll data={data} count={count} />}
//   />
// )
